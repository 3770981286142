import * as React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/legacy";

type Props = {
  stats: ReadonlyArray<
    | undefined
    | {
        stat_value: string | undefined;
        stat_label: string | undefined;
      }
  >;
};

export default function Stats({ stats }: Props) {
  return (
    <StyledContainer>
      {stats.map((stat, i) => {
        if (!stat) return null;
        const { stat_value, stat_label } = stat;
        if (!stat_value || !stat_label) return null;
        return (
          <StyledItem key={i.toString()}>
            <StyledValue $xl={stat_value.length <= 3}>{stat_value}</StyledValue>
            <StyledLabel>{stat_label}</StyledLabel>
            <StyledLine />
          </StyledItem>
        );
      })}
    </StyledContainer>
  );
}

const BREAKPOINT = 680;

const StyledContainer = styled.div`
  background-color: ${Color.blue};
  display: flex;
  justify-content: center;

  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    align-items: stretch;
    padding: 15px 0;
  }
`;

const StyledItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 25px;
  max-width: 320px;

  @media (max-width: ${BREAKPOINT}px) {
    padding: 10px 30px;
    flex-direction: row;
  }
`;

const StyledValue = styled.div<{ $xl: boolean }>`
  box-sizing: border-box;
  width: 92px;
  height: 92px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => (p.$xl ? "32px" : "24px")};
  font-weight: 700;
  background-color: ${Color.darkBlue};
  color: ${Color.cyan};

  margin-bottom: 18px;

  @media (max-width: ${BREAKPOINT}px) {
    margin-bottom: 0;
    margin-right: 25px;
  }
`;

const StyledLabel = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: ${Color.white};
  flex: 1;

  @media (max-width: ${BREAKPOINT}px) {
    text-align: left;
    max-width: 240px;
  }
`;

const StyledLine = styled.div`
  width: 100%;
  max-width: 168px;
  height: 1px;
  background-color: ${Color.white};
  margin-top: 16px;

  @media (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`;
