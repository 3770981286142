import { graphql } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import styled from "styled-components";

import {
  Layout,
  HelmetSeo,
  Hero,
  Button,
  Section,
  HorizontalContainer,
  Cols,
  Col,
  FormattedRichText,
} from "../components/legacy";
import { Stats } from "../components/legacy/case-studies";
import QUOTE_LEFT from "../../img/pages/home/quote_left.svg";
import QUOTE_RIGHT from "../../img/pages/home/quote_right.svg";
import { Color } from "../constants/legacy";
import { Modal } from "../components";
import { CaseStudyDownloadForm } from "../components/case-study";

export const pageQuery = graphql`
  query CaseStudy($uid: String!) {
    prismicCaseStudy(uid: { eq: $uid }) {
      data {
        company_name
        pardot_form
        preview_text
        preview_logo_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
        logo_white_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
        hero_title
        hero_description
        download_url
        download_button_label

        stats {
          stat_value
          stat_label
        }

        infos {
          info_label
          info_value
        }

        cta_text
        cta_button_label

        body {
          __typename
          ... on PrismicCaseStudyBodyText {
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicCaseStudyBodyQuote {
            primary {
              quote_content
              author
            }
          }
        }

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

type Props = {
  data: GatsbyTypes.CaseStudyQuery;
};

export default function CaseStudyTemplate({ data }: Props) {
  const caseStudy = data.prismicCaseStudy?.data;
  if (!caseStudy) {
    return null;
  }

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const logoSrc = caseStudy.preview_logo_image?.url;

  return (
    <Layout>
      {/* The case study has enough info to create defaults for all of these,
            but let the user override them if they want */}
      <HelmetSeo
        seo_title={
          caseStudy.seo_title ||
          `Case Study: ${caseStudy.company_name} | Shipamax`
        }
        seo_description={caseStudy.seo_description || caseStudy.preview_text}
        share_image={caseStudy.share_image}
      />

      <Hero
        preTitle="Customer Story"
        preTitleColor="orange"
        title={caseStudy.hero_title || ""}
        subTitle={caseStudy.hero_description}
        button={
          caseStudy.download_url && (
            <Button
              label={
                caseStudy.download_button_label || "Download case study PDF"
              }
              onClick={() => setModalVisible(true)}
            />
          )
        }
        backgroundColor="darkBlue"
        denseContent
        offsetBottom={100}
        leftLogoSrc={caseStudy.logo_white_image?.url}
      />

      {caseStudy.stats && (
        <StyledStatsWrapper>
          <HorizontalContainer>
            <Stats stats={caseStudy.stats} />
          </HorizontalContainer>
        </StyledStatsWrapper>
      )}

      <Section>
        <HorizontalContainer>
          <Cols>
            <Col flex={525}>
              <StyledContentWrap>
                {caseStudy.body?.map((slice, i) => {
                  if (!slice) return null;
                  const key = i.toString();
                  if (slice.__typename === "PrismicCaseStudyBodyText") {
                    if (!slice.primary) {
                      throw new Error("slice.primary is missing");
                    }
                    return (
                      <FormattedRichText
                        key={key}
                        render={slice.primary.content?.raw}
                      />
                    );
                  } else if (slice.__typename === "PrismicCaseStudyBodyQuote") {
                    if (!slice.primary) {
                      throw new Error("slice.primary is missing");
                    }
                    return (
                      <StyledBlockquote key={key}>
                        <p>{slice.primary.quote_content}</p>
                        <cite>{slice.primary.author}</cite>
                        <img src={QUOTE_LEFT} className="quote-left" />
                        <img src={QUOTE_RIGHT} className="quote-right" />
                      </StyledBlockquote>
                    );
                  } else {
                    throw new Error(
                      `Unknown slice type: ${(slice as any).__typename}`
                    );
                  }
                })}
              </StyledContentWrap>
            </Col>
            <Col flex={130} />
            <Col flex={300} joinedCss="margin-left: 30px">
              <StyledInfoBox>
                <StyledInfoBoxAreaImage>
                  {logoSrc && <StyledLogoImg src={logoSrc} />}
                </StyledInfoBoxAreaImage>
                <StyledInfoBoxAreaContent>
                  <b>{caseStudy.company_name}</b>
                  <dl>
                    {caseStudy.infos?.map((info, i) => {
                      if (!info) return null;
                      return (
                        <div key={i.toString()}>
                          <dt>{info.info_label}:</dt> <dd>{info.info_value}</dd>
                        </div>
                      );
                    })}
                  </dl>
                </StyledInfoBoxAreaContent>
              </StyledInfoBox>

              <Button label="Contact us" to="/contact" color="cyan" />
            </Col>
          </Cols>
        </HorizontalContainer>
      </Section>

      {caseStudy.download_url && (
        <Modal onDismiss={() => setModalVisible(false)} visible={modalVisible}>
          <CaseStudyDownloadForm
            successUrl={caseStudy.download_url}
            pardotForm={caseStudy.pardot_form ?? ""}
          />
        </Modal>
      )}
    </Layout>
  );
}

const StyledStatsWrapper = styled.div`
  margin-top: -90px;
  position: relative;
  z-index: 2;
`;

const StyledContentWrap = styled.div`
  padding-bottom: 70px;
`;

const StyledBlockquote = styled.blockquote`
  margin: 50px 0;
  padding: 35px 70px;
  background-color: #f1f1f1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 400px) {
    padding: 35px 25px;
  }

  p {
    max-width: 300px;
    font-size: 18px;
    margin: 0 0 25px;
  }

  cite {
    font-weight: 300;
    font-style: normal;
  }

  .quote-left {
    position: absolute;
    top: 35px;
    left: 25px;

    @media (max-width: 400px) {
      display: none;
    }
  }

  .quote-right {
    position: absolute;
    top: 35px;
    right: 25px;

    @media (max-width: 400px) {
      display: none;
    }
  }
`;

const StyledInfoBox = styled.div`
  box-shadow: 0 2px 6px #0006;
  margin-bottom: 30px;
`;

const StyledInfoBoxAreaImage = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  background-color: #f4f4f4;
`;

const StyledLogoImg = styled.img`
  width: 85%;
  height: 85%;
  object-fit: contain;
`;

const StyledInfoBoxAreaContent = styled.div`
  padding: 25px;

  b {
    display: block;
    font-size: 18px;
  }

  dl {
    margin: 0;

    > div {
      margin: 15px 0 0;
    }

    dd,
    dt {
      display: inline;
    }
  }
`;
