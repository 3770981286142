import React from "react";
import { useForm } from "react-typed-form";
import styled from "styled-components";

import { Column, Grid, Gutter, HorizontalAlign, Text } from "..";
import { FieldSelect, FieldText, SubmitButton } from "../../form";

type Props = {
  successUrl: string;
  pardotForm: string;
};

export default function CaseStudyDownloadForm({
  successUrl,
  pardotForm,
}: Props) {
  return (
    <iframe src={pardotForm} width="100%" height="570" frameBorder="0" allowTransparency={true} style={{ border: 0 }}></iframe>
  );
}

const StyledTopRow = styled.div`
  display: flex;
  align-items: center;

  > span {
    flex: 1;
  }
`;
